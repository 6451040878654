<template>
  <b-row>
    <b-col md="6" class="my-1">
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="Brand Article Number"
        label-align-sm="right"
        label-size="sm"
        label-for="order_id"
        class="required"
      >
        <sv-select></sv-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BrandArticleNumber',

  data() {
    return {};
  },
  props: {},
  components: {},
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
